export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const THRESHOLD = 550; // Set a threshold to avoid hitting the rate limit
export function calculateDelay(throttleStatus: {
  maximumAvailable: number;
  currentlyAvailable: number;
  restoreRate: number;
}): number {
  const { maximumAvailable, currentlyAvailable, restoreRate } = throttleStatus;

  if (currentlyAvailable > THRESHOLD) {
    // If there is enough buffer remaining, no need for a delay.
    return 0;
  }
  const calcDelay = ((maximumAvailable - currentlyAvailable) / restoreRate) * 1000;
  return Math.max(calcDelay, 1000);
}
